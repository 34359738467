<template>
	<main>
		<slot />
		<HokFooter />
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokFooter from '~/components/HokFooter.vue';

export default defineComponent({
	name: 'HeaderlessLayout',
	components: { HokFooter }
});
</script>
